import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@naf/button';
import { Grid } from '@naf/grid';
import { breakpoints, spacing } from '@naf/theme';
import { Directions } from '@styled-icons/material-outlined/Directions';
import { Text, TextVariant } from '@naf/text';
import { PracticeTrackType } from '../../../../../types/PracticeTrackType';
import { useDocument } from '../../../hooks/useDocument';
import { useSendGTMEventOnce } from '../../../hooks/GTM/useSendGTMEventOnce';
import { LoaderContent } from '../../LoaderPage';
import { useWindowLocation } from '../../../hooks/Window/useWindowLocation';
import BlockContent from '../../../components/block-content/BlockContent';
import carIcon from '../../../../assets/images/carPOI.svg?url';
import BreadCrumb from '../../../components/breadcrumb/BreadCrumb';
import { useFetchCity } from '../../../hooks/useFetchCity';
import { OneLineLoader } from '../../../components/skeleton-loaders/OneLineLoader';
import { useAuth0Token } from '../../../hooks/useAuth0Token';
import { useHandleNafLocationsRoutes } from '../useHandleNafLocationsRoutes';
import { LayoutWithMainContent } from '../../../components/layout/Layout';
import { InternalSearchCardType } from '../../../components/layout/InternalSearchMetaData';
import { MapWrapper, Marker } from '../../../loadable-elements/LoadableGoogleMapsAPI';
import { NotFound } from '../../../loadable-elements/NotFound';

type Props = {
  match: {
    params: { slug: string };
  };
};
const PracticeTrack = ({
  match: {
    params: { slug },
  },
}: Props) => {
  const { simpleToken } = useAuth0Token();
  const { data, meta } = useDocument(slug, simpleToken);
  const practiceTrackData = data as PracticeTrackType;
  const url = useWindowLocation();
  const { locations } = useHandleNafLocationsRoutes();

  useSendGTMEventOnce(
    data && {
      page_type: 'nafLocationsApp',
      pageCategory: `Her finner du NAF / ${Object.values(locations).find((l) => url.includes(l.fullUrl))?.name} / ${
        practiceTrackData.title
      }`,
      contentId: practiceTrackData.id,
    },
    [data],
  );
  const { cityName } = useFetchCity(practiceTrackData?.contactInfo?.postalCode);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  if (!data && meta.isUpdating) {
    return <LoaderContent />;
  }
  if (!data) {
    return <NotFound />;
  }
  const { title, ingress, coordinates, seoConfig, body, contactInfo } = practiceTrackData;

  return (
    <LayoutWithMainContent
      title={title}
      description={seoConfig?.introduction || ingress}
      seoDescription={seoConfig?.introduction || undefined}
      isHiddenFromSearch={seoConfig?.isHiddenFromSearch}
      seoTitle={seoConfig?.title || title}
      internalSearchMetaData={{
        cardType: InternalSearchCardType.PracticeTrack,
        streetAddress: contactInfo?.streetAddress,
        postalCode: contactInfo?.postalCode,
        contactPhone: contactInfo?.phoneNumber,
        contactEMail: contactInfo?.email,
      }}
      url={url}
    >
      <StyledBreadcrumb />
      <StyledGrid s="6">
        <Header variant={TextVariant.Header1}>{title}</Header>
        <Ingress variant={TextVariant.Ingress}>{ingress}</Ingress>
        {coordinates && (
          <Map>
            <MapWrapper
              mapOptions={{
                mapId: '241506239fc98522',
                disableDefaultUI: true,
              }}
              containerStyle={{
                width: '100%',
                height: isMobile ? '90vh' : '524px',
              }}
              center={{ lat: coordinates.latitude, lng: coordinates.longitude }}
              zoom={9}
            >
              <Marker position={{ lat: coordinates.latitude, lng: coordinates.longitude }} icon={{ url: carIcon }} />
              <Mapbutton
                variant="spruce"
                href={`https://www.google.com/maps/dir/?api=1&destination=${coordinates.latitude},${coordinates.longitude}&travelmode=driving`}
                target="_blank"
              >
                <Directions height={16} width={16} />
                Få veibeskrivelse
              </Mapbutton>
            </MapWrapper>
          </Map>
        )}
        <Body>
          <BlockContent value={body} />
        </Body>
        <Contact>
          <div>
            <ContactHeader variant={TextVariant.BodyTextHeader}>Adresse</ContactHeader>
            <NoMarginText variant={TextVariant.BodyText}>{contactInfo.streetAddress}</NoMarginText>
            {contactInfo.postalCode && (
              <NoMarginText variant={TextVariant.BodyText}>
                {contactInfo.postalCode} {cityName || <OneLineLoader width="32px" height="16px" />}
              </NoMarginText>
            )}
          </div>
          <div>
            <ContactHeader variant={TextVariant.BodyTextHeader}>Kontakt</ContactHeader>
            <NoMarginText variant={TextVariant.BodyText}>{contactInfo.contactPersonName}</NoMarginText>
            {contactInfo.phoneNumber && (
              <NoMarginText variant={TextVariant.BodyText}>Tlf: {contactInfo.phoneNumber}</NoMarginText>
            )}
            {contactInfo.email && <NoMarginText variant={TextVariant.BodyText}>{contactInfo.email}</NoMarginText>}
          </div>
        </Contact>
      </StyledGrid>
    </LayoutWithMainContent>
  );
};

const StyledGrid = styled(Grid)`
  row-gap: 0;
`;

const Header = styled(Text)`
  grid-column: 3 / 10;

  @media (max-width: ${breakpoints.s}) {
    grid-column: 1 / 13;
    margin: ${spacing.space24} 0;
  }
  @media (max-width: ${breakpoints.l}) and (min-width: ${breakpoints.s}) {
    grid-column: 3 / 11;
  }
`;

const Ingress = styled(Text)`
  grid-column: 3 / 10;
  margin-top: 0;
  margin-bottom: ${spacing.space64};

  @media (max-width: ${breakpoints.s}) {
    grid-column: 1 / 13;
    margin-bottom: ${spacing.space40};
  }
  @media (max-width: ${breakpoints.l}) and (min-width: ${breakpoints.s}) {
    grid-column: 3 / 11;
  }
`;

const StyledBreadcrumb = styled(BreadCrumb)`
  grid-column: 3 / 10;

  @media (max-width: ${breakpoints.s}) {
    grid-column: 1 / 13;
  }
`;

const Map = styled.div`
  grid-column: 1 / 13;
  width: 100%;
  @media (max-width: ${breakpoints.s}) {
    grid-column: 1 / 13;
    margin-left: -${spacing.space24};
    margin-right: -${spacing.space24};
    width: 100vw;
  }
`;

const Body = styled.div`
  grid-column: 4 / 9;
  margin-top: ${spacing.space64};

  & h2,
  & h3 {
    margin-top: 0;
  }

  @media (max-width: ${breakpoints.s}) {
    grid-row: 5;
    grid-column: 1 / 13;
  }

  @media (max-width: ${breakpoints.m}) and (min-width: ${breakpoints.s}) {
    grid-column: 1 / 9;
  }
  @media (max-width: ${breakpoints.l}) and (min-width: ${breakpoints.m}) {
    grid-column: 3 / 9;
  }
`;

const Contact = styled.div`
  grid-column: 9 / 11;
  margin-top: ${spacing.space40};
  @media (max-width: ${breakpoints.s}) {
    margin-top: ${spacing.space16};
    grid-column: 1 / 13;
    grid-row: 4;
    display: flex;
    gap: ${spacing.space24};
  }
`;

const Mapbutton = styled(Button)`
  position: absolute;
  bottom: 32px;
  right: 40px;

  & > span {
    display: flex;
    gap: ${spacing.space12};
  }
`;

const ContactHeader = styled(Text)`
  margin-top: ${spacing.space24};
  margin-bottom: ${spacing.space8};
`;

const NoMarginText = styled(Text)`
  margin: 0;
  text-wrap: nowrap;
`;

export default PracticeTrack;
